import de from 'vee-validate/dist/locale/de.json';

export default {
    home: 'Heim',
    about: 'Über das Unternehmen',
    technologies: 'Technologien',
    contact: 'Kontaktiere Uns',
    title: 'Prozessautomatisierung und Programmierung',
    subheading: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro ratione ut non ea tempora nostrum at voluptatibus?',
    showcase: 'Wir verwenden die besten Technologien, um die Bedürfnisse unserer Kunden zu erfüllen:',
    aboutCompanyTitle: 'Über unsere Firma',
    aboutFirstPerson: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita veritatis aperiam dolor est, voluptatibus ipsa blanditiis nemo ullam ipsum enim eveniet omnis, laboriosam cupiditate nesciunt. Adipisci ullam ipsum et iusto. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit mollitia est at, vitae sapiente pariatur reiciendis quis hic expedita voluptate?',
    aboutSecondPerson: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita veritatis aperiam dolor est, voluptatibus ipsa blanditiis nemo ullam ipsum enim eveniet omnis, laboriosam cupiditate nesciunt. Adipisci ullam ipsum et iusto. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit mollitia est at, vitae sapiente pariatur reiciendis quis hic expedita voluptate?',
    contactInfoTitle: 'Kontaktinformationen',
    contactInfoSubheading: 'Wenn Sie uns kontaktieren möchten, füllen Sie das Formular aus oder kontaktieren Sie uns direkt!',
    footer: 'SPSABLE. Alle Rechte vorbehalten',
    fields: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'Email',
        phoneNumber: 'Telefonnummer',
        message: 'Nachricht',
        reset: 'Zurücksetzen',
        submit: 'Einreichen',
    },
    validation: de.messages,
}