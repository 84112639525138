import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import Antd from 'ant-design-vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import '@/assets/less/style.less';

Vue.use(Antd)
Vue.use(VueReCaptcha, { siteKey: '6LfMITwgAAAAAPvJpMALjUdBwBAnsPw9H0Ri3OKc' })

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
