import en from 'vee-validate/dist/locale/en.json';

export default {
    home: 'Home',
    about: 'About Company',
    technologies: 'Technologies',
    contact: 'Contact Us',
    title: 'Process Automation and Programming',
    subheading: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro ratione ut non ea tempora nostrum at voluptatibus?',
    showcase: 'We are using best technologies to satisfy our client needs:',
    aboutCompanyTitle: 'About Our Company',
    aboutFirstPerson: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita veritatis aperiam dolor est, voluptatibus ipsa blanditiis nemo ullam ipsum enim eveniet omnis, laboriosam cupiditate nesciunt. Adipisci ullam ipsum et iusto. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit mollitia est at, vitae sapiente pariatur reiciendis quis hic expedita voluptate?',
    aboutSecondPerson: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita veritatis aperiam dolor est, voluptatibus ipsa blanditiis nemo ullam ipsum enim eveniet omnis, laboriosam cupiditate nesciunt. Adipisci ullam ipsum et iusto. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit mollitia est at, vitae sapiente pariatur reiciendis quis hic expedita voluptate?',
    contactInfoTitle: 'Contact Information',
    contactInfoSubheading: 'If you would like to contact us fill in the form or contact us directly!',
    footer: 'SPSABLE. All rights reserved',
    fields: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phoneNumber: 'Phone number',
        message: 'Message',
        reset: 'Reset',
        submit: 'Submit'
    },
    validation: en.messages,
}